import React, { useState } from 'react'
import CachedContext from '../hooks/useCoursesContext'

function CachedProvider({ children }: { children: React.ReactNode }) {
  const [context] = useState(new Map<string, Promise<any>>())

  return <CachedContext.Provider value={context}>{children}</CachedContext.Provider>
}

export default CachedProvider
