import 'semantic-ui-css/semantic.min.css'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'

import App from './App'
import locale_en from './locales/en.json'
import locale_pt from './locales/pt.json'

import './authentication/msal-setup'
import store from './store'

const locales = {
  en: locale_en,
  pt: locale_pt,
}

const language = 'pt'
const messages = (locales as any)[language]

const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages} defaultLocale="ptBR">
      <App />
    </IntlProvider>
  </Provider>,
  rootElement
)
