import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum AppEvents {
  BooksInserted = 'booksInserted',
  SophiaCodeInserted = 'sophiaCodeInserted',
  PendingUpdateBooksUpdated = 'pendingUpdateBooksUpdated',
}

export type AppEventsState = { [key in AppEvents]: number }

export const initialState: AppEventsState = {
  [AppEvents.BooksInserted]: 0,
  [AppEvents.SophiaCodeInserted]: 0,
  [AppEvents.PendingUpdateBooksUpdated]: 0,
}

type AppEventsReducer<T> = CaseReducer<AppEventsState, PayloadAction<T>>

const notifyEvent: AppEventsReducer<AppEvents> = (state, { payload }) => {
  return {
    ...state,
    [payload]: state[payload] + 1,
  }
}

const slice = createSlice({
  name: 'appEvents',
  initialState,
  reducers: {
    notifyEvent,
  },
})

export default slice
