import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon, IconProps, Segment, Transition } from 'semantic-ui-react'
import useToast from '../hooks/useToast'
import ToastType from '../models/toast-type'
import LayoutContainer from './LayoutContainer'

/* eslint react/jsx-props-no-spreading: 0 */

const Container = styled(Segment)`
  border-radius: 10px !important;
  width: 426px;
`

const Message = styled.div`
  padding: 0 0.5rem;
`

interface ToastProps {
  children: React.ReactNode
  id: number
  type: ToastType
}

const DefaultToastDuration = 5000

const IconConfig: { [key in ToastType]: IconProps } = {
  [ToastType.Warning]: {
    name: 'warning sign',
    color: 'yellow',
  },
  [ToastType.Success]: {
    name: 'check circle outline',
    color: 'green',
  },
  [ToastType.Error]: {
    name: 'times circle outline',
    color: 'red',
  },
  [ToastType.Info]: {
    name: 'info circle',
    color: 'blue',
  },
}

function Toast({ children, id, type }: ToastProps) {
  const { removeToast } = useToast()
  const [isVisible, setIsVisible] = useState(true)

  const handleOnHideAnimationComplete = () => removeToast(id)
  const handleClose = () => setIsVisible(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(false), DefaultToastDuration)
    return () => {
      clearTimeout(timer)
    }
  }, [id, removeToast])

  return (
    <Transition transitionOnMount onHide={handleOnHideAnimationComplete} visible={isVisible}>
      <Container raised padded data-cy-toast={type}>
        <LayoutContainer centered="vertical" spread>
          <LayoutContainer>
            <Icon {...IconConfig[type]} size="large" />
            <Message>{children}</Message>
          </LayoutContainer>
          <Icon name="times" size="large" color="grey" link onClick={handleClose} />
        </LayoutContainer>
      </Container>
    </Transition>
  )
}

export default Toast
