import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import ToastComponent from './Toast'
import { ToastConfig } from '../models/toast-config'

interface ToastContainerProps {
  toasts: ToastConfig[]
}

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  padding-bottom: 30px;
  padding-right: 30px;
  z-index: 2000; // modal z-index is 1000
`

function ToastContainer({ toasts }: ToastContainerProps) {
  return createPortal(
    <Wrapper>
      {toasts.map((toast) => (
        <ToastComponent key={toast.id} id={toast.id} type={toast.type}>
          {toast.content}
        </ToastComponent>
      ))}
    </Wrapper>,
    document.body
  )
}

export default ToastContainer
