function useIdCreator() {
  let id = 0

  const createId = () => {
    id += 1
    return id
  }

  return createId
}

export default useIdCreator
