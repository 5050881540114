import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Country } from '../models/country'

export const countryAdapter = createEntityAdapter<Country>()

const countrySlice = createSlice({
  name: 'countries',
  initialState: countryAdapter.getInitialState(),
  reducers: {
    setCountries: countryAdapter.setAll,
  },
})

export default countrySlice
