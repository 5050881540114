import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Provider, Interceptors, CachePolicies } from 'use-http'
import TagManager from 'react-gtm-module'

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import LoadingPanel from './components/LoadingPanel'
import ProtectedRoute from './components/ProtectedRoute'
import useAuth from './hooks/useAuth'
import ToastProvider from './components/ToastProvider'
import CachedProvider from './components/CoursesProvider'
import { reactPlugin } from './utils/app-insights.utils'

const BooksPage = React.lazy(() => import('./pages/BooksPage'))
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'))
const LoginPage = React.lazy(() => import('./pages/LoginPage'))
const AuthPage = React.lazy(() => import('./pages/AuthPage'))
const OwnersPage = React.lazy(() => import('./pages/OwnersPage'))
const CatalogingPage = React.lazy(() => import('./pages/CatalogingPage'))
const CollectionPage = React.lazy(() => import('./pages/CollectionPage'))

function App() {
  const { getToken, authReady } = useAuth()
  const [authLoaded, setAuthLoaded] = useState(false)

  useEffect(() => {
    authReady.then(() => setAuthLoaded(true))
  }, [])

  const interceptors: Interceptors = {
    request: async ({ options }) => {
      const token = await getToken()
      const headers = new Headers(options.headers)
      headers.append('Authorization', `Bearer ${token}`)
      return {
        ...options,
        headers,
      }
    },
  }

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID ?? '',
  }

  if (tagManagerArgs.gtmId) {
    TagManager.initialize(tagManagerArgs)
  }

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        {!authLoaded && <LoadingPanel />}
        <ToastProvider>
          <CachedProvider>
            <BrowserRouter>
              <Provider
                url={process.env.REACT_APP_API_URL}
                options={{ suspense: false, interceptors, cachePolicy: CachePolicies.NO_CACHE }}
              >
                <Suspense fallback={<LoadingPanel />}>
                  <Switch>
                    <ProtectedRoute path="/" component={DashboardPage} exact />
                    <ProtectedRoute path="/books/:tab" component={BooksPage} />
                    <ProtectedRoute path="/books/" component={BooksPage} />
                    <ProtectedRoute path="/owners" component={OwnersPage} />
                    <ProtectedRoute path="/cataloging/:tab" component={CatalogingPage} />
                    <ProtectedRoute path="/cataloging" component={CatalogingPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/auth" component={AuthPage} />
                    <Route path="/catalogos" component={CollectionPage} />
                    <Redirect from="/collection" to="/catalogos" />
                    <Redirect to="/" />
                  </Switch>
                </Suspense>
              </Provider>
            </BrowserRouter>
          </CachedProvider>
        </ToastProvider>
      </AppInsightsContext.Provider>
    </>
  )
}

export default App
