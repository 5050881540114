import * as Msal from '@azure/msal-browser'
import * as authStorage from './authentication-storage'

const baseEl = document.getElementsByTagName('base')[0]
const baseUrl = baseEl ? baseEl.href : 'https://localhost:44343/'

export const msalInstance = new Msal.PublicClientApplication({
  auth: {
    clientId: '7534295f-762f-4b9e-bb97-9d845dd3b9b9',
    authority: 'https://login.microsoftonline.com/6de22b39-13f1-466d-98be-3dcbe120dde0',
    navigateToLoginRequestUrl: false,
    redirectUri: `${baseUrl}auth`,
    postLogoutRedirectUri: `${baseUrl}login`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
})

export const userScopes: string[] = [
  'openid offline_access api://7534295f-762f-4b9e-bb97-9d845dd3b9b9/user_impersonation',
]

export const MsalReadyPromise = new Promise<void>((resolve) => {
  msalInstance.handleRedirectPromise().then((value) => {
    if (value) {
      const accountId = value.account?.homeAccountId
      if (accountId) {
        authStorage.setAccountId(accountId)
      } else {
        authStorage.clearStorage()
      }
    }
    resolve()
  })
})
