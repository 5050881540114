import React from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function ProtectedRoute(props: RouteProps) {
  const { user } = useAuth()
  const location = useLocation()

  return user ? (
    // eslint-disable-next-line
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  )
}

export default ProtectedRoute
