import { CaseReducer, PayloadAction, AnyAction, createSlice } from '@reduxjs/toolkit'
import { BooksFilterResponse } from '../../models/reponses/books-filter.response'

export interface BooksFilterState {
  isbnList?: string
  title?: string
  author?: string
  segmentTypeId?: number
  technicalCatalogTypes?: number[]
  areaIds?: string[]
  courseIds?: string[]
  disciplineIds?: string[]
  ownerId?: string
  imprintId?: string
  hasSophiaId?: boolean
  firstCreatedDate?: number
  lastCreatedDate?: number
  firstRemovedDate?: number
  lastRemovedDate?: number
  publicationDate?: number
  sortBy?: string
}

export const initialState: BooksFilterState = {}

type BooksFilterReducer<T> = CaseReducer<BooksFilterState, PayloadAction<T>>

const createReducer = <T extends keyof BooksFilterState>(prop: T): BooksFilterReducer<BooksFilterState[T]> => {
  return (state, { payload }) => {
    return {
      ...state,
      [prop]: payload,
    }
  }
}

const createDateReducer = (prop: keyof BooksFilterState): BooksFilterReducer<Date | undefined> => {
  return (state, { payload }) => ({
    ...state,
    [prop]: payload?.getTime(),
  })
}

const reset: CaseReducer<BooksFilterState, AnyAction> = () => ({
  ...initialState,
})

const updateAll: BooksFilterReducer<BooksFilterState> = (state, { payload }) => {
  return {
    ...payload,
  }
}

const updateCatalogIdsFilter: BooksFilterReducer<number | undefined> = (state, { payload }) => {
  let newState: BooksFilterState = { ...state, segmentTypeId: payload }
  if (typeof payload === 'number' && payload !== -1) {
    newState = { ...newState, technicalCatalogTypes: [], areaIds: [], courseIds: [], disciplineIds: [] }
  }
  return newState
}

const updateCollectionAreaIdsFilter: BooksFilterReducer<string[] | undefined> = (state, { payload }) => {
  let newState: BooksFilterState = { ...state, areaIds: payload }
  if (payload?.length !== 1) {
    newState = { ...newState, courseIds: [], disciplineIds: [] }
  }
  return newState
}

const updateCollectionCourseIdsFilter: BooksFilterReducer<string[] | undefined> = (state, { payload }) => {
  let newState: BooksFilterState = { ...state, courseIds: payload }
  if (payload?.length !== 1) {
    newState = { ...newState, disciplineIds: [] }
  }
  return newState
}

const removeFilter: BooksFilterReducer<keyof BooksFilterState> = (state, { payload }) => {
  const newState = { ...state }
  delete newState[payload]
  return newState
}

const normalizeDate = (date?: string): number | undefined => {
  return date ? new Date(date).getTime() : undefined
}

const loadFromBookFilterResponse: BooksFilterReducer<BooksFilterResponse> = (state, { payload }) => {
  return {
    sortBy: payload.sortBy,
    isbnList: payload.isbnList,
    title: payload.title,
    author: payload.author,
    imprintId: payload.imprintId,
    ownerId: payload.ownerId,
    hasSophiaId: payload.hasSophiaId,
    firstCreatedDate: normalizeDate(payload.firstCreatedDate),
    lastCreatedDate: normalizeDate(payload.lastCreatedDate),
    firstRemovedDate: normalizeDate(payload.firstRemovedDate),
    lastRemovedDate: normalizeDate(payload.lastRemovedDate),
    segmentTypeId: payload.segmentType,
    technicalCatalogTypes: payload.technicalCatalogTypes.map((tc) => tc.id),
    areaIds: payload.areas.map((area) => area.id),
    courseIds: payload.courses.map((course) => course.id),
    disciplineIds: payload.disciplines.map((discipline) => discipline.id),
  }
}

const slice = createSlice({
  name: 'booksFilter',
  initialState,
  reducers: {
    updateIsbn: createReducer('isbnList'),
    updateTitle: createReducer('title'),
    updateAuthor: createReducer('author'),
    updateSegmentTypeId: createReducer('segmentTypeId'),
    updateTechnicalCatalogTypes: createReducer('technicalCatalogTypes'),
    updateAreaIds: createReducer('areaIds'),
    updateCourseIds: createReducer('courseIds'),
    updateDisciplineIds: createReducer('disciplineIds'),
    updateCatalogIdsFilter,
    updateCollectionAreaIdsFilter,
    updateCollectionCourseIdsFilter,
    updateOwnerId: createReducer('ownerId'),
    updateImprintId: createReducer('imprintId'),
    updateHasSophiaId: createReducer('hasSophiaId'),
    updateFirstCreatedDate: createDateReducer('firstCreatedDate'),
    updateLastCreatedDate: createDateReducer('lastCreatedDate'),
    updateFirstRemovedDate: createDateReducer('firstRemovedDate'),
    updateLastRemovedDate: createDateReducer('lastRemovedDate'),
    updateSortOption: createReducer('sortBy'),
    removeFilter,
    updateAll,
    reset,
    loadFromBookFilterResponse,
  },
})

export default slice
