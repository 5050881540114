import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Area } from '../models/area'

export const areaAdapter = createEntityAdapter<Area>()

const areaSlice = createSlice({
  name: 'areas',
  initialState: areaAdapter.getInitialState(),
  reducers: {
    setAreas: areaAdapter.setAll,
  },
})

export default areaSlice
