import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { TechnicalCatalog } from '../models/technical-catalog'

export const technicalCatalogAdapter = createEntityAdapter<TechnicalCatalog>()

const technicalCatalogSlice = createSlice({
  name: 'technicalCatalogs',
  initialState: technicalCatalogAdapter.getInitialState(),
  reducers: {
    setTechnicalCatalogs: technicalCatalogAdapter.setAll,
  },
})

export default technicalCatalogSlice
