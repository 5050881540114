import { configureStore } from '@reduxjs/toolkit'
import areaSlice from './slices/area.slice'
import courseSlice from './slices/course.slice'
import disciplineSlice from './slices/discipline.slice'
import imprintSlice from './slices/imprint.slice'
import ownerSlice from './slices/owner.slice'
import countrySlice from './slices/country.slice'
import booksFilterSlice from './slices/books-filter/books-filter.slice'
import appEventsSlice from './slices/app-events.slice'
import technicalCatalogSlice from './slices/technical-catalog.slice'

const store = configureStore({
  reducer: {
    [technicalCatalogSlice.name]: technicalCatalogSlice.reducer,
    [areaSlice.name]: areaSlice.reducer,
    [courseSlice.name]: courseSlice.reducer,
    [disciplineSlice.name]: disciplineSlice.reducer,
    [imprintSlice.name]: imprintSlice.reducer,
    [ownerSlice.name]: ownerSlice.reducer,
    [countrySlice.name]: countrySlice.reducer,
    [booksFilterSlice.name]: booksFilterSlice.reducer,
    [appEventsSlice.name]: appEventsSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store
