enum StorageKeys {
  AccountId = 'msal.account-id',
  SessionExpired = 'msal.session-expired',
}

export const getAccountId = (): string | null => {
  return localStorage.getItem(StorageKeys.AccountId) || null
}

export const setAccountId = (accountId: string): void => {
  localStorage.setItem(StorageKeys.AccountId, accountId)
}

export const isSessionExpired = (): boolean => {
  return localStorage.getItem(StorageKeys.SessionExpired) !== null
}

export const setSessionAsExpired = () => {
  localStorage.setItem(StorageKeys.SessionExpired, 'true')
}

export const setSessionAsNotExpired = () => {
  localStorage.removeItem(StorageKeys.SessionExpired)
}

export const clearStorage = (): void => {
  Object.values(StorageKeys).forEach((key) => localStorage.removeItem(key))
}
