import styled from 'styled-components'

export enum LayoutContainerPadding {
  None = '0rem',
  Smaller = '0.25rem',
  Small = '0.5rem',
  Medium = '1rem',
  Large = '2rem',
  Larger = '3rem',
}

type LayoutContainerProps = {
  vertical?: boolean
  padding?: LayoutContainerPadding
  horizontalPadding?: LayoutContainerPadding
  verticalPadding?: LayoutContainerPadding
  topPadding?: LayoutContainerPadding
  bottomPadding?: LayoutContainerPadding
  centered?: 'horizontal' | 'vertical' | boolean
  right?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  wrapped?: boolean
  spread?: boolean
}

const LayoutContainer = styled.div<LayoutContainerProps>`
  display: flex;

  ${({ vertical }) => `flex-direction: ${vertical ? `column` : `row`};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ horizontalPadding }) =>
    horizontalPadding && `padding-right: ${horizontalPadding}; padding-left: ${horizontalPadding};`}
  ${({ verticalPadding }) => verticalPadding && `padding-top: ${verticalPadding}; padding-bottom: ${verticalPadding};`}
  ${({ topPadding }) => topPadding && `padding-top: ${topPadding};`}
  ${({ bottomPadding }) => bottomPadding && `padding-bottom: ${bottomPadding};`}
  ${({ centered, vertical }) => {
    if (centered === true) {
      return `align-items: center; justify-content: center;`
    }
    if (vertical) {
      if (centered === 'vertical') {
        return `justify-content: center;`
      }
      if (centered === 'horizontal') {
        return `align-items: center;`
      }
    }
    if (!vertical) {
      if (centered === 'vertical') {
        return `align-items: center;`
      }
      if (centered === 'horizontal') {
        return `justify-content: center;`
      }
    }
    return ''
  }}
  ${({ right }) => right && `justify-content: flex-end;`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
  ${({ wrapped }) => wrapped && `flex-wrap: wrap;`}
  ${({ spread }) => spread && `justify-content: space-between;`}
`

export default LayoutContainer
