import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { useFormatMessage } from './intl-hook'

interface LoadingPanelProps {
  fixed?: boolean
}

function LoadingPanel({ fixed }: LoadingPanelProps) {
  const translate = useFormatMessage()

  return (
    <Dimmer active inverted data-cy="loading-panel">
      <StyledLoader fixed={fixed} inverted content={translate({ id: 'Loading' })} />
    </Dimmer>
  )
}

const StyledLoader = styled(Loader)<LoadingPanelProps>`
  ${({ fixed }) => fixed && `position: fixed !important;`}
`

export default LoadingPanel
