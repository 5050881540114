import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Owner } from '../models/owner'

export const ownerAdapter = createEntityAdapter<Owner>()

const ownerSlice = createSlice({
  name: 'owners',
  initialState: ownerAdapter.getInitialState(),
  reducers: {
    setOwners: ownerAdapter.setAll,
  },
})

export default ownerSlice
