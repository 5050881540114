import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Imprint } from '../models/imprint'

export const imprintAdapter = createEntityAdapter<Imprint>()

const imprintSlice = createSlice({
  name: 'imprints',
  initialState: imprintAdapter.getInitialState(),
  reducers: {
    setImprints: imprintAdapter.setAll,
  },
})

export default imprintSlice
