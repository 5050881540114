import React, { useCallback, useState } from 'react'
import { ToastConfig } from '../models/toast-config'
import ToastContainer from './ToastContainer'
import ToastContext from '../contexts/toast.context'
import ToastType from '../models/toast-type'
import useIdCreator from '../hooks/useIdCreator'

function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState<ToastConfig[]>([])
  const createId = useIdCreator()

  const addToast = useCallback(
    (content: string | React.ReactNode, type: ToastType) => {
      setToasts((currentToasts) => [
        {
          id: createId(),
          content,
          type,
        },
        ...currentToasts,
      ])
    },
    [setToasts]
  )

  const addSuccessToast = useCallback(
    (content: string | React.ReactNode) => addToast(content, ToastType.Success),
    [addToast]
  )
  const addWarningToast = useCallback(
    (content: string | React.ReactNode) => addToast(content, ToastType.Warning),
    [addToast]
  )
  const addErrorToast = useCallback(
    (content: string | React.ReactNode) => addToast(content, ToastType.Error),
    [addToast]
  )
  const addInfoToast = useCallback((content: string | React.ReactNode) => addToast(content, ToastType.Info), [addToast])

  const removeToast = useCallback(
    (toastId: number) => {
      setToasts((currentToasts) => currentToasts.filter((t) => t.id !== toastId))
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={{ addSuccessToast, addErrorToast, addInfoToast, addWarningToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
