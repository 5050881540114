import { InteractionRequiredAuthError } from '@azure/msal-browser'
import * as authStorage from '../authentication/authentication-storage'
import { msalInstance, MsalReadyPromise, userScopes } from '../authentication/msal-setup'

const login = () => {
  msalInstance.loginRedirect({
    scopes: userScopes,
    prompt: 'select_account',
  })
}

const logout = () => msalInstance.logout()

const getCurrentUser = () => {
  const accountId = authStorage.getAccountId()
  if (accountId) {
    return msalInstance.getAccountByHomeId(accountId)
  }
  return null
}

const hasPermission = (permission: string): boolean => {
  const user = getCurrentUser()
  if (user) {
    const { roles } = user.idTokenClaims as any
    if (roles) {
      return roles.includes(permission)
    }
  }

  return false
}

const getToken = async () => {
  const account = getCurrentUser()
  if (!account) {
    return null
  }
  const response = await msalInstance.acquireTokenSilent({
    scopes: userScopes,
    account,
  })
  return response.accessToken
}

const AuthReadyPromise = new Promise<void>((resolve) => {
  MsalReadyPromise.then(async () => {
    try {
      if (getCurrentUser() != null) {
        await getToken()
        authStorage.setSessionAsNotExpired()
      }
    } catch (error) {
      authStorage.clearStorage()
      if (error instanceof InteractionRequiredAuthError) {
        authStorage.setSessionAsExpired()
      }
      window.location.reload()
    } finally {
      resolve()
    }
  })
})

function useAuth() {
  return {
    user: getCurrentUser(),
    login,
    logout,
    getToken,
    hasPermission,
    authReady: AuthReadyPromise,
  }
}

export default useAuth
