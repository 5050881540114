import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Discipline } from '../models/discipline'

export const disciplineAdapter = createEntityAdapter<Discipline>()

const disciplineSlice = createSlice({
  name: 'disciplines',
  initialState: disciplineAdapter.getInitialState(),
  reducers: {
    setDisciplines: disciplineAdapter.setAll,
    addDiscipline: disciplineAdapter.addOne,
  },
})

export default disciplineSlice
