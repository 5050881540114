import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Course } from '../models/course'

export const courseAdapter = createEntityAdapter<Course>()

const courseSlice = createSlice({
  name: 'courses',
  initialState: courseAdapter.getInitialState(),
  reducers: {
    setCourses: courseAdapter.setAll,
  },
})

export default courseSlice
